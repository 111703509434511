<template>
  <section ref="section">
    <div class="left__box">
      <header ref="header">
        <img :src="props.section.logo" alt="" />
      </header>
      <ul>
        <li
          ref="li"
          v-for="(description, index) in props.section.items"
          :key="index"
        >
          <div
            :style="{ backgroundColor: props.section.color }"
            class="separator"
          ></div>
          <span>
            <b>{{ description.header }}</b
            ><br />
            {{ description.text }}
          </span>
        </li>
      </ul>
    </div>
    <div class="mockups">
      <img
        ref="mockup"
        :class="{
          mobile: props.section.type === 'mobile',
          desktop: props.section.type === 'desktop',
        }"
        class="asset"
        :src="props.section.mockups[0]"
        alt=""
      />
    </div>
  </section>
</template>

<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ref, onMounted, defineProps } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useUtilitiesStore } from "@/store/utils";
const store = useUtilitiesStore();
const { bgColor2 } = storeToRefs(store);
const route = useRoute();
gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  section: {},
});

const section = ref();
const header = ref();
const li = ref();
const mockup = ref();
const elements = [header, mockup, li];

onMounted(() => {
  smartAnimate();
  changeBG(props.section.color);
});

const smartAnimate = () => {
  console.log(route.path);
  if (route.path === "/WebApps") {
    elements.forEach((element, index) => {
      gsap.to(element.value, {
        opacity: 1,
        x: "0%",
        duration: 1.3,
        delay: index / 5,
        ease: "elastic.out(1, 0.9)",
        scrollTrigger: {
          trigger: section.value,
          start: "10% 50%",
        },
      });
      gsap.to(section.value, {
        scrollTrigger: {
          trigger: section.value,
          onEnter: () => changeBG(props.section.color),
          onEnterBack: () => changeBG(props.section.color),
          start: "10% 50%",
        },
      });
    });
  }
};

const changeBG = (color) => {
  if (route.path === "/WebApps") {
    bgColor2.value = color;
  }
};
</script>

<style scoped>
header,
ul li,
.asset {
  opacity: 0;
  transform: translateX(120px);
}
.mobile.asset {
  width: 500px;
  max-height: 800px;
}
.mockups {
  max-width: 50%;
  transition: 0.8s all;
  cursor: pointer;
}
.mockups:hover {
  max-width: 100%;
}
.separator {
  min-width: 5px;
  height: 30px;
  background-color: #356ebf;
}
section {
  min-height: 100vh;
  padding: 2rem;
  position: relative;
  overflow-y: visible;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
header img {
  max-width: 250px;
  border-radius: 50px;
}

.left__box {
  max-width: 40%;
  z-index: 10;
}
.left__box header {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  gap: 1rem;
}
.left__box header h1 {
  margin: 0;
}
.line {
  width: 50%;
  height: 3px;
  background-color: #eb5e28;
  margin: 0.5rem 0;
}
ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
}
ul li {
  color: white;
  font-weight: 300;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
}
@media screen and (max-width: 1400px) {
  .mockups {
    max-width: unset !important;
  }
}
@media screen and (max-width: 900px) {
  section {
    padding: 1rem;
    padding-top: 6rem;
  }
  .left__box {
    max-width: 100%;
    z-index: 10;
    padding: 1rem;
    border-radius: 10px;
  }
  section {
    justify-content: center;
    flex-wrap: wrap;
  }
  .mockups {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    justify-content: center;
    height: fit-content;
  }
  .asset.mobile {
    max-width: 90%;
  }
  .asset.desktop {
    width: 100%;
    overflow-x: scroll;
  }
}
</style>

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

import SectionBadge from "./components/Badges/SectionBadge.vue";
import SectionBadgeService from "./components/Badges/SectionBadgeServices.vue";
import SectionBadgeBlog from "./components/Badges/SectionBadgeBlog.vue";
import SectionBadgeContact from "./components/Badges/SectionBadgeContact.vue";
import BounceIcon from "./components/animations/BounceIcon.vue";
import TheLoading from "./components/animations/TheLoading.vue";

const app = createApp(App);
const pinia = createPinia();

app.component("section-badge", SectionBadge);
app.component("section-badge-service", SectionBadgeService);
app.component("section-badge-blog", SectionBadgeBlog);
app.component("section-badge-contact", SectionBadgeContact);
app.component("bounce-icon", BounceIcon);
app.component("the-loading", TheLoading);

app.use(router);
app.use(pinia);

app.mount("#app");

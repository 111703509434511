<template>
  <svg
    class="background"
    width="960"
    height="100%"
    viewBox="0 0 960 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="BG">
      <circle id="orange" cx="1088" cy="214" r="1088" :fill="bgColor2" />
      <circle id="white" cx="1048.5" cy="276.5" r="1008.5" fill="#FFFCF2" />
    </g>
  </svg>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUtilitiesStore } from "../../store/utils";
const store = useUtilitiesStore();
const { bgColor2 } = storeToRefs(store);
</script>
<style scoped>
.background {
  width: 50%;
  position: fixed;
  right: 0;
  top: 0%;
  z-index: -1;
  transition: 0.3s all;
}
circle {
  transition: 0.3s all;
}
#bg {
  transition: 0.3s all;
}
@media screen and (max-width: 1000px) {
  #white {
    fill: #252422;
  }
}
@media screen and (max-width: 700px) {
  .background {
    width: 100%;
    right: -80%;
  }
}
</style>

<template>
  <div class="header">
    <header :class="{ scrolled: isScrolled }">
      <div class="logo">
        <router-link to="/">
          <the-logo :isScrolled="isScrolled" />
        </router-link>
      </div>
      <ul class="nav__links">
        <router-link to="/" active-class="link-active"
          ><li @click="toggleNav">Home</li></router-link
        >
        <router-link to="/WebApps" active-class="link-active"
          ><li @click="toggleNav">Software Development</li></router-link
        >
        <router-link to="/DevOps" active-class="link-active"
          ><li @click="toggleNav">Infrastruktura</li></router-link
        >
        <router-link to="/HelpDesk" active-class="link-active"
          ><li @click="toggleNav">Helpdesk IT</li></router-link
        >
        <router-link
          :to="{ path: '/', hash: '#contact' }"
          active-class="link-active"
          ><li class="contact__link" @click="toggleNav">
            Kontakt
          </li></router-link
        >
      </ul>
      <the-burger :isOpen="isOpen" @toggle-nav="toggleNav"></the-burger>
    </header>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUtilitiesStore } from "@/store/utils";
const store = useUtilitiesStore();
const { bgColor2 } = storeToRefs(store);
import TheLogo from "../SVGs/TheLogo.vue";
import TheBurger from "./TheBurger.vue";
import { onMounted, onUnmounted, ref } from "vue";
const isOpen = ref(false);

const toggleNav = () => {
  const nav = document.querySelector(".nav__links");
  const navLinks = document.querySelectorAll(".nav__links li");

  nav.classList.toggle("nav__active");
  isOpen.value = !isOpen.value;

  navLinks.forEach((link, index) => {
    if (link.style.animation) {
      link.style.animation = "";
    } else if (window.innerWidth < 701) {
      link.style.animation = `navLinkFade 0.5s ease forwards ${
        index / 7 + 0.051
      }s`;
    }
  });
};
const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.pageYOffset > 0;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped>
.scrolled {
  background: rgba(255, 255, 255, 0.62);
  backdrop-filter: blur(13.5px);
  padding: 0.8rem 2rem;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 200;
}
header {
  left: 0;
  top: 0;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s all;
}
.nav__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  gap: 2rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s all;
  padding: 0;
}
.nav__links li {
  color: #252422;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  transition: 0.4s all;
  overflow: visible;
  padding: 0.5rem 1rem;
  border-radius: 30px;
}
.nav__links li:hover {
  background-color: v-bind(bgColor2);
  color: rgb(255, 252, 242);
}
@media screen and (max-width: 1700px) {
  header {
    background: rgba(255, 255, 255, 0.62);
    backdrop-filter: blur(13.5px);
    padding: 0.8rem 2rem;
  }
}
@media screen and (max-width: 700px) {
  .nav__links {
    position: fixed;
    height: calc(100vh - 62px);
    width: 100%;
    top: 0;
    left: 0;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 62px;
    background-color: #252422;
    transform: translateX(100%);
  }
  .nav__links li {
    font-size: 1.3rem;
    font-weight: 300;
    opacity: 0;
    transform: translateX(1200px) rotate(30deg) scale(10%);
    color: white;
  }
  .nav__links li:hover {
    font-size: 1.3rem;
  }
  .nav__active {
    transform: translateX(0%);
  }
  a.link-active li {
    color: #e5e5e5;
    font-size: 1.3rem;
  }
  a.link-active li::after {
    transform: scaleX(1);
    transform-origin: left;
  }
}
</style>

<style>
@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(1200px) rotate(30deg) scale(10%);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>

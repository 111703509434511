<template>
  <section ref="services" id="services">
    <div class="left_top content box">
      <div ref="card1" class="service">
        <div class="service_content">
          <div class="icon">
            <img src="1.svg" alt="" />
            <h1 class="heading_font">Software development</h1>
          </div>
          <div class="line"></div>
          <ul class="description">
            <li>Front-end Development</li>
            <li>Back-end Development</li>
            <li>Bazy Danych</li>
            <li>Security</li>
            <li>Testowanie i Debugowanie</li>
            <li>Deployment i Utrzymanie</li>
          </ul>
          <div class="stack__container">
            <h2>Technologie w których pracujemy:</h2>
            <div class="stack">
              <img class="tech" src="techstack/typescript.svg" alt="TS" />
              <img class="tech" src="techstack/logos_javascript.svg" alt="JS" />
              <img class="tech" src="techstack/logos_node.svg" alt="NODEjs" />
              <img class="tech" src="techstack/logos_nestjs.svg" alt="NESTjs" />
              <img
                class="tech"
                src="techstack/logos_nuxt-icon.svg"
                alt="NUXTjs"
              />
              <img class="tech" src="techstack/logos_vue.svg" alt="VUEjs" />
              <img class="tech" src="techstack/logos_react.svg" alt="REACTjs" />
              <img class="tech" src="techstack/wordpress.svg" alt="WP" />
            </div>
          </div>
          <div class="buttons__container">
            <div class="btn btn_more">
              <router-link
                :to="{ path: '/', hash: '#contact' }"
                active-class="link-active"
              >
                <h2>Kontakt</h2>
              </router-link>
            </div>
            <div class="btn">
              <router-link
                :to="{ path: '/WebApps' }"
                active-class="link-active"
              >
                <h2>Więcej</h2>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div ref="card2" class="service">
        <div class="service_content">
          <div class="icon">
            <img src="2.svg" alt="" />
            <h1 class="heading_font">Administracja infrastrukturą IT</h1>
          </div>
          <div class="line"></div>
          <ul class="description">
            <li>Administracja infrastrukturą sieciową</li>
            <li>Migracje do chmury</li>
            <li>Automatyzacja i CI/CD</li>
            <li>Monitorowanie i Logowanie</li>
            <li>Konfiguracja Infrastruktury jako Kod (IaC)</li>
            <li>Konteneryzacja i Orkiestracja Kontenerów</li>
            <li></li>
            <li></li>
          </ul>
          <div class="stack__container">
            <h2>Technologie w których pracujemy:</h2>
            <div class="stack">
              <img class="tech" src="techstack/kubernetes.svg" alt="K8S" />
              <img class="tech" src="techstack/docker.svg" alt="DOCKER" />
              <img class="tech" src="techstack/ansible.svg" alt="ANSIBLE" />
              <img class="tech" src="techstack/terraform.svg" alt="TERRAFORM" />
              <img class="tech" src="techstack/prometeus.svg" alt="PROMETEUS" />
              <img class="tech" src="techstack/gitlab.svg" alt="GITLAB" />
              <img class="tech" src="techstack/github.svg" alt="GITHUB" />
              <img class="tech" src="techstack/gcp.svg" alt="GCP" />
              <img class="tech" src="techstack/aws.svg" alt="AWS" />
              <img class="tech" src="techstack/azure.svg" alt="AZURE" />
            </div>
          </div>
          <div class="buttons__container">
            <div class="btn btn_more">
              <router-link
                :to="{ path: '/', hash: '#contact' }"
                active-class="link-active"
              >
                <h2>Kontakt</h2>
              </router-link>
            </div>
            <div class="btn">
              <router-link :to="{ path: '/DevOps' }" active-class="link-active">
                <h2>Więcej</h2>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div ref="card3" class="service">
        <div class="service_content">
          <div class="icon">
            <img src="3.svg" alt="" />
            <h1 class="heading_font">helpdesk / szkolenia IT</h1>
          </div>
          <div class="line"></div>
          <ul class="description">
            <li>Obsługa zgłoszeń i wsparcie techniczne</li>
            <li>Diagnoza i rozwiązywanie problemów</li>
            <li>Bezpieczeństwo IT</li>
            <li>Monitorowanie systemów</li>
            <li>Zarządzanie incydentami</li>
          </ul>
          <div class="stack__container">
            <h2>Szkolenia:</h2>
            <ul>
              <li>
                <b>Web Development Essentials:</b> Podstawy Tworzenia Stron
                Internetowych
              </li>
              <li>
                <b>DevOps Starter Pack:</b> Wprowadzenie do Kultury DevOps i
                Narzędzi
              </li>
              <li>
                <b>Introduction to IT Security:</b> Wprowadzenie do Podstaw
                Bezpieczeństwa IT
              </li>
              <li>
                <b>Introduction to Secure Coding:</b> Wprowadzenie do
                Bezpiecznego Kodowania
              </li>
            </ul>
          </div>
          <div class="buttons__container">
            <div class="btn btn_more">
              <router-link
                :to="{ path: '/', hash: '#contact' }"
                active-class="link-active"
              >
                <h2>Kontakt</h2>
              </router-link>
            </div>
            <div class="btn">
              <router-link
                :to="{ path: '/helpdesk' }"
                active-class="link-active"
              >
                <h2>Więcej</h2>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useUtilitiesStore } from "@/store/utils";
const store = useUtilitiesStore();
const { bgColor2 } = storeToRefs(store);

const services = ref();
const card1 = ref();
const card2 = ref();
const card3 = ref();

const cards = [card1, card2, card3];

gsap.registerPlugin(ScrollTrigger);
onMounted(() => {
  bgColor2.value = "#EB5E28";
  cards.forEach((card, index) => {
    gsap.to(card.value, {
      opacity: 1,
      x: "0%",
      duration: 2.3,
      delay: index / 5,
      ease: "elastic.out(2, 0.9)",
      scrollTrigger: {
        trigger: card.value,
        start: "30% 70%",
      },
    });
    const techIcons = card.value.querySelectorAll(".tech");

    techIcons.forEach((icon, index) => {
      icon.style.opacity = 0;
      icon.style.transform = "translateX(20%)";
      gsap.to(icon, {
        scrollTrigger: {
          trigger: card.value,
          start: "30% 70%",
        },
        opacity: 1,
        x: "0%",
        duration: 1,
        delay: index / 5,
        ease: "elastic.out(8, 0.9)",
      });
    });
  });
});
</script>

<style scoped>
section {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3rem;
  left: 0;
  flex-wrap: wrap;
  overflow: hidden;
}
.left_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
  flex-wrap: wrap;
}
.service {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  width: 30%;
  min-width: 500px;
  transform: translateX(20%);
  opacity: 0;
}
.service_content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  gap: 0.5rem;
  background-color: #403d3986;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.14);
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.14);
  padding: 2rem;
  width: 100%;
  min-height: 450px;
}
.left_top h1 {
  font-size: 30px;
  line-height: 30px;
  margin: 0;
}
.left_top h2 {
  font-size: 1.1rem;
  color: #fffcf2;
  font-weight: 300;
  margin: 0;
  overflow: hidden;
  position: relative;
  transition: 0.5s all;
}

.line {
  width: 30%;
  height: 4px;
  margin: 0.5rem 0;
  background-color: #eb5e28;
}

.stack {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
}
.stack__container h2 {
  font-size: 1.2rem;
}

.stack img {
  max-height: 30px;
}

ul {
  color: #fffcf2;
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

.buttons__container {
  position: absolute;
  bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0;
}
.btn {
  background-color: #eb5e28;
  color: #fffcf2 !important;
  border-radius: 20px;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s all;
}
.btn_more {
  background-color: transparent;
  color: #fffcf2;
  border: 2px #eb5e28 solid;
  border-style: inset;
}
.btn h2 {
  padding: 6px 25px;
  color: #fffcf2;
  pointer-events: none;
  font-weight: 400;
}
.btn_more h2 {
  color: #eb5e28 !important;
}

.btn:hover {
  opacity: 0.8;
}

.icon {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.icon img {
  width: 50px;
}
@media screen and (max-width: 700px) {
  .stack img {
    max-height: 20px;
  }
  section {
    padding: 0.5rem;
  }
  .service {
    min-width: unset !important;
    width: 90%;
  }
  .service_content {
    height: 550px;
  }
}
</style>

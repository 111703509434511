<template>
  <section>
    <div class="left__box">
      <header ref="header">Rozwiązania chmurowe / automatyzacja</header>
      <ul>
        <li ref="li">
          <div class="separator"></div>

          <span>
            <b>Administracja infrastrukturą sieciową</b><br />
            Oferujemy kompleksowe rozwiązania w zarządzaniu infrastrukturą
            sieciową, zapewniając szybkość, niezawodność i skalowalność Twojej
            działalności.</span
          >
        </li>
        <li ref="li2">
          <div class="separator"></div>
          <span
            ><b>Migracje do chmury</b><br />Specjalizujemy się w bezproblemowych
            migracjach Twoich danych i aplikacji do chmury, gwarantując
            efektywność i bezpieczeństwo procesu.</span
          >
        </li>
        <li ref="li3">
          <div class="separator"></div>
          <span
            ><b>Automatyzacja i CI/CD</b><br />Jesteśmy specjalistami w
            dziedzinie automatyzacji procesów IT oraz implementacji metodyki
            DevOps. Naszym celem jest dostarczanie rozwiązań, które
            przyspieszają i usprawniają procesy w Twojej firmie, zapewniając
            efektywność, niezawodność i skalowalność.</span
          >
        </li>
        <li ref="li4">
          <div class="separator"></div>
          <span
            ><b>Monitorowanie i Logowanie</b><br />Zapewniamy pełne
            monitorowanie i logowanie Twoich systemów, umożliwiając szybką
            reakcję na ewentualne zagrożenia oraz optymalizację
            wydajności.</span
          >
        </li>
        <li ref="li5">
          <div class="separator"></div>
          <span
            ><b>Konfiguracja Infrastruktury jako Kod (IaC)</b><br />Nasze
            rozwiązania opierają się na IaC, co umożliwia automatyzację i
            standaryzację procesów konfiguracyjnych, przyspieszając wdrażanie i
            zwiększając niezawodność infrastruktury.</span
          >
        </li>
        <li ref="li6">
          <div class="separator"></div>
          <span
            ><b>Konteneryzacja i Orkiestracja Kontenerów</b><br />Dzięki naszym
            usługom, Twoje aplikacje są łatwiejsze do zarządzania, bardziej
            elastyczne i łatwiej skalowalne, co przyczynia się do efektywności
            działania Twojej firmy.</span
          >
        </li>
      </ul>
    </div>
    <div ref="mockup" class="asset">
      <img src="grafana.png" alt="" />
    </div>
  </section>
</template>

<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ref, onMounted } from "vue";
gsap.registerPlugin(ScrollTrigger);

const li = ref();
const li2 = ref();
const li3 = ref();
const li4 = ref();
const li5 = ref();
const li6 = ref();
const header = ref();
const mockup = ref();

const elements = [header, li, li2, li3, li4, li5, li6, mockup];

onMounted(() => {
  smartAnimate();
});

const smartAnimate = () => {
  elements.forEach((element, index) => {
    gsap.to(element.value, {
      opacity: 1,
      x: "0%",
      duration: 1.3,
      delay: index / 5,
      ease: "elastic.out(1, 0.9)",
    });
  });
};
</script>

<style scoped>
section {
  min-height: calc(100vh - 12rem);
  padding: 2rem;
  padding-top: 10rem;
  position: relative;
  overflow-y: visible;
}
.left__box {
  max-width: 40%;
  z-index: 10;
}
.left__box h1 {
  font-size: 1rem;
}
.left__box header {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  gap: 1rem;
  width: 100%;
}
.left__box header h1 {
  margin: 0;
}
header {
  color: white;
  font-size: 2rem;
}
header,
ul li,
.asset {
  opacity: 0;
  transform: translateX(120px);
}
ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
}
ul li {
  color: white;
  font-weight: 300;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
}
.separator {
  min-width: 5px;
  height: 30px;
  background-color: #eb5e28;
}
.asset {
  position: fixed;
  right: 0;
  bottom: -6vh;
  overflow-y: visible;
  z-index: -1;
}
.asset img {
  max-height: 90vh;
}

@media screen and (max-width: 800px) {
  section {
    padding: 1rem;
    padding-top: 6rem;
  }
  .left__box {
    max-width: 100%;
    z-index: 10;
    padding: 1rem;
    border-radius: 10px;
  }
  .asset {
    display: none;
  }
}
</style>

<script>
export default {
  props: ["isScrolled"],
  data() {
    return {};
  },
};
</script>

<template>
  <svg
    :class="{ isScrolled: isScrolled }"
    width="1473"
    viewBox="0 0 1473 315"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M209.182 29.2635L155.389 143.326L87.3406 0.0987854H0.49646L149.558 314.421H152.393C157.332 290.211 164.859 264.591 164.678 257.867C159.929 259.141 154.957 262.156 144.509 263.766C144.651 256.148 146.762 249.221 149.358 241.199C152.037 232.925 159.83 223.194 162.817 217.461C164.807 213.643 164.377 207.963 164.85 203.375C165.68 195.325 172.432 187.457 183.279 176.786C188.555 171.596 193.868 163.654 195.176 155.081C196.817 144.324 199.52 143.664 201.634 129.019C201.838 127.604 203.386 127.815 204.992 128.331C208.741 129.54 211.178 136.198 214.164 137.922C216.381 139.202 218.894 140.718 219.631 142.326C220.792 144.857 220.489 148.251 220.445 149.9C220.398 151.708 224.67 154.62 223.86 154.935C222.788 155.352 219.651 155.913 219.514 157.019C218.557 164.664 220.174 175.899 221.173 177.378C226.003 177.326 226.22 175.106 227.992 173.53C230.328 171.457 231.661 169.647 232.44 168.655C233.224 167.657 231.204 165.367 231.567 164.384C231.88 163.538 235.706 164.479 236.762 163.016C237.568 161.9 238.316 160.426 239.319 157.419C240.398 154.186 252.167 161.891 251.84 165.91C251.594 168.953 247.661 171.212 246.884 173.592C245.476 177.922 246.44 197.174 240.154 214.779C245.659 227.107 249.782 238.028 253.892 248.678C254.184 249.357 253.209 248.668 252.3 248.091C257.259 257.71 262.903 277.927 265.858 288.838C264.796 288.218 264.586 288.241 263.665 287.735C264.725 292.42 265.964 304.725 266.215 314.421H268.806L417.867 0.0987854H331.023L262.974 143.326L209.182 29.2635ZM319.91 0.0987854H256.166L287.95 67.4942L319.91 0.0987854ZM162.199 0.0987854H98.4539L130.238 67.4942L162.199 0.0987854ZM343.664 314.421H279.919L311.703 247.026L343.664 314.421ZM138.445 314.421H74.6999L106.484 247.026L138.445 314.421ZM185.952 198.81C183.035 200.049 178.622 201.924 178.823 200.767C179.329 197.851 183.151 190.821 186.348 189.152C188.307 188.13 190.826 186.753 192.453 186.699C193.585 186.661 189.35 197.367 185.952 198.81Z"
    />
    <path
      d="M1420.6 190.597L1398.06 168.1H1472.9V136.475H1398.06L1366.37 104.85V184.8L1403.87 222.221H1472.9V190.597H1420.6ZM1366.37 222.221H1391.29L1366.37 197.351V222.221ZM1398.06 123.922H1472.64V92.2977H1366.37L1398.06 123.922Z"
    />
    <path
      d="M1196.39 222.221H1228.08V183.122L1196.39 151.496V222.221ZM1274.03 168.1L1328.26 222.221H1279.84L1196.39 138.944V92.2977H1280.77C1301.7 92.2977 1318.67 109.267 1318.67 130.199C1318.67 151.131 1301.7 168.1 1280.77 168.1H1274.03ZM1229.76 123.922L1242.34 136.475H1280.77C1284.24 136.475 1287.04 133.676 1287.04 130.199C1287.04 126.722 1284.24 123.922 1280.77 123.922H1229.76Z"
    />
    <path
      d="M1096.66 92.2977L1078.27 131.307L1121.38 222.221H1158.28L1096.66 92.2977ZM1091.75 180.47L1071.95 222.221H1035.05L1073.3 141.569L1091.75 180.47Z"
    />
    <path
      d="M730.745 136.475L699.056 104.85V222.222H730.745V168.1H774.164V136.475H730.745ZM730.746 123.922H809.214V92.2977H699.056L730.746 123.922Z"
    />
    <path
      d="M660.945 222.221V190.597H616.335L648.024 222.221H660.945ZM603.758 190.597V92.2977H572.133V222.221H635.446L603.758 190.597Z"
    />
    <path
      d="M514.153 111.361C501.802 99.0101 485.424 92.2981 467.971 92.2981C453.473 92.2981 439.779 97.131 428.234 105.992L425.818 107.871L448.908 130.917L450.787 129.843C455.889 126.62 462.065 125.054 468.24 125.054C477.101 125.054 485.424 128.5 491.6 134.675C497.775 140.806 501.265 149.129 501.265 157.945C501.265 164.076 499.654 170.252 496.432 175.352L495.358 177.188L518.449 200.233L520.329 197.816C529.189 186.316 534.022 172.667 534.022 158.213C534.022 140.806 527.31 124.518 514.959 112.166L514.153 111.361ZM423.966 203.159C436.317 215.51 452.695 222.222 470.148 222.222C484.647 222.222 498.34 217.389 509.885 208.528L512.302 206.649L489.211 183.603L487.332 184.677C482.23 187.899 476.054 189.466 469.879 189.466C461.019 189.466 452.695 186.02 446.52 179.844C440.345 173.714 436.854 165.39 436.854 156.575C436.854 150.444 438.465 144.268 441.687 139.168L442.761 137.332L419.67 114.287L417.791 116.703C408.93 128.203 404.097 141.853 404.097 156.307C404.097 173.714 410.81 190.002 423.161 202.353L423.966 203.159Z"
    />
    <path
      d="M955.772 222.221L937.376 183.212L980.489 92.2977H1017.38L955.772 222.221ZM908.937 144.419L928.156 103.89L946.605 142.788L908.937 222.221L847.325 92.2977H884.221L908.937 144.419Z"
    />
  </svg>
</template>

<style scoped>
.isScrolled {
  fill: #403d39;
  opacity: 0.7;
}
svg {
  width: 150px;
  transition: 0.5s all;
}

@media screen and (min-width: 2000px) {
  svg {
    width: 250px;
  }
}
</style>

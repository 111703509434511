<template>
  <div v-if="cookiesVisible" class="backdrop CookieBox">
    <main class="modal">
      <img src="CookieAsset.png" alt="" />
      <div class="modalContent">
        <div class="text">
          <h2>
            Serwis wykorzystuje pliki cookies m.in. w celu poprawienia jej
            dostępności, personalizacji, obsługi kont użytkowników czy aby
            zbierać dane, dotyczące ruchu na stronie. Każdy może sam decydować o
            tym czy dopuszcza pliki cookies, ustawiając odpowiednio swoją
            przeglądarkę. Więcej informacji znajdziesz w Polityce
          </h2>
          <a href="rodo.docx">Prywatności i Regulaminie.</a>
        </div>
      </div>
      <div class="modalNav">
        <bounce-icon
          ><button @click="acceptCookies">Akceptuj</button></bounce-icon
        >
        <bounce-icon
          ><button class="closeBtn" @click="closeCookies">
            Anuluj
          </button></bounce-icon
        >
      </div>
    </main>
  </div>
</template>

<script>
export default {
  emits: ["modal-handler"],
  data() {
    return {
      cookiesVisible: true,
    };
  },
  methods: {
    closeCookies() {
      this.cookiesVisible = false;
    },
    acceptCookies() {
      this.cookiesVisible = false;
      document.cookie =
        "CookieBy=Nicode.pl/Nicode; max-age=" + 60 * 60 * 24 * 30;
    },
  },
  beforeMount() {
    if (document.cookie.indexOf("CookieBy=Nicode.pl/Nicode") != -1) {
      this.cookiesVisible = false;
    } else {
      this.cookiesVisible = true;
    }
  },
};
</script>

<style scoped>
.hide {
  display: none !important;
}
.modal {
  position: fixed;
  left: 2rem;
  bottom: 2rem;
  z-index: 300;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 60%;
  border-radius: 10px;
  padding: 1rem;
  gap: 1rem;
  opacity: 0;
  transform: translateY(200px) scale(0.7);
  overflow: hidden;
  animation: showModal 0.5s forwards;
  animation-delay: 1s;
}
.modalNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
@keyframes showModal {
  0% {
    opacity: 0;
    transform: translateY(200px) scale(0.7);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
.modalContent {
  max-width: 50%;
}
.text h2 {
  font-weight: 200;
  font-size: 1rem;
}
.text a {
  color: #eb5e28 !important;
  text-decoration: underline;
}
button {
  border-color: #eb5e28;
  border: 1px #eb5e28 solid;
  border-radius: 3px;
  padding: 1rem 3rem;
  font-size: 1rem;
  color: #eb5e28;
  background-color: white;
  cursor: pointer;
}
.closeBtn {
  color: white;
  background-color: #eb5e28;
}
@media screen and (max-width: 900px) {
  .modal {
    width: calc(100% - 6rem);
    max-width: unset;
    flex-direction: column;
    align-items: flex-start;
    left: 1rem;
    padding: 2rem;
  }

  .modalContent {
    max-width: 100%;
  }
  .text h2 {
    font-weight: 200;
    font-size: 0.8rem;
  }
  button {
    padding: 0.8rem 2rem;
    font-size: 0.9rem;
  }
}
</style>

import { defineStore } from "pinia";

export const useUtilitiesStore = defineStore("utilities", {
  state: () => ({
    bgColor1: "#252422",
    bgColor2: "#EB5E28",
  }),
  getters: {},
  actions: {},
});

<template>
  <section ref="contactSection">
    <div class="left_top content box">
      <form class="form" ref="form" @submit.prevent="sendEmail">
        <div class="form__container">
          <div class="input__group" ref="ig1">
            <label for="from_name"
              >Imię i nazwisko <span class="invalid__text">*</span></label
            >
            <input
              required
              :class="{ invalid: nameValidity === 'invalid' }"
              v-model.trim="from_name"
              type="text"
              name="from_name"
              placeholder="Wprowadź imię i nazwisko..."
              @blur="validateInputName"
            />
            <p v-if="nameValidity === 'invalid'">to pole nie może być puste</p>
          </div>
          <div class="input__group" ref="ig2">
            <label for="from_name">Firma</label>
            <input
              v-model="company"
              type="text"
              name="company"
              placeholder="Wprowadź nazwę firmy..."
            />
          </div>
          <div class="input__group" ref="ig3">
            <label for="from_name"
              >Email <span class="invalid__text">*</span></label
            >
            <input
              required
              :class="{ invalid: mailValidity === 'invalid' }"
              v-model="from_email"
              type="from_email"
              name="from_email"
              placeholder="Wprowadź email..."
              @blur="validateInputMail"
            />
            <p v-if="mailValidity === 'invalid'">to pole nie może być puste</p>
          </div>
          <div class="input__group" ref="ig4">
            <label for="from_name"
              >Opis <span class="invalid__text">*</span></label
            >
            <textarea
              required
              :class="{ invalid: contentValidity === 'invalid' }"
              v-model="mailContent"
              name="mailContent"
              placeholder="Wprowadź treść..."
              @blur="validateInputContent"
            ></textarea>
            <p v-if="contentValidity === 'invalid'">
              to pole nie może być puste
            </p>
          </div>
          <div class="input__group__CheckBox" ref="ig5">
            <input
              class="checkbox__input"
              type="checkbox"
              name="myCheckboxName"
              required
            />
            <h1>
              * Wyrażam dobrowolną zgodę na przetwarzanie przez NiCode - Nikodem
              Wicon z siedzibą w Wejherowie przy ulicy Św. Jana 7c/38, moich
              danych osobowych zawartych w tym formularzu w celu i zakresie
              niezbędnym do odpowiedzi na to pytanie. Potwierdzam zapoznanie się
              z
              <a href="Rodo.docx" download>polityką prywatności.</a>
            </h1>
          </div>
          <div ref="ig6" class="input__group">
            <button type="submit" value="Send" required>Wyślij</button>
          </div>
        </div>
        <div class="loading__container">
          <the-loading
            v-if="waiting"
            :color="'#FCAF30'"
            :color2="'rgb(252, 175, 48)'"
          ></the-loading>
        </div>
      </form>
    </div>
    <div ref="ig7" class="contact_buttons">
      <bounce-icon
        ><a href="callto:+48696787013"><img src="phone.svg" alt="" /></a
      ></bounce-icon>
      <bounce-icon
        ><a href="mailto:biuro@gcodem.pl"><img src="mail.svg" alt="" /></a
      ></bounce-icon>
    </div>
  </section>
</template>

<script setup>
import emailjs from "@emailjs/browser";
import { ref, defineEmits, onMounted } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const emit = defineEmits(["send-validity"]);
const from_name = ref(null);
const form = ref("");
const company = ref(null);
const from_email = ref(null);
const mailContent = ref(null);
const sendValidity = ref("");
const waiting = ref(false);
let nameValidity = "pending";
let mailValidity = "pending";
let contentValidity = "pending";

const cleanInputs = () => {
  from_name.value = "";
  company.value = "";
  from_email.value = "";
  mailContent.value = "";
};

const sendEmail = () => {
  waiting.value = true;
  emailjs
    .sendForm("Nicode", "template_k3nl7xv", form.value, "d_4z_Zpr1-3s9lpTd", {
      company: company.value,
      from_name: from_name.value,
      from_email: from_email.value,
      emailContent: mailContent.value,
    })
    .then(
      (result) => {
        console.log("SUCCESS!", result.text);
        emit("send-validity", "success");
        sendValidity.value = "success";
        waiting.value = false;
        cleanInputs();
      },
      (error) => {
        console.log("FAILED...", error.text);
        emit("send-validity", "failed");
        sendValidity.value = "failed";
        waiting.value = false;
        cleanInputs();
      }
    );
};

const validateInputName = () => {
  if (from_name.value === "") {
    nameValidity = "invalid";
  } else {
    nameValidity = "valid";
  }
};
const validateInputMail = () => {
  if (from_email.value === "") {
    mailValidity = "invalid";
  } else {
    mailValidity = "valid";
  }
};
const validateInputContent = () => {
  if (mailContent.value === "") {
    contentValidity = "invalid";
  } else {
    contentValidity = "valid";
  }
};
const ig1 = ref();
const ig2 = ref();
const ig3 = ref();
const ig4 = ref();
const ig5 = ref();
const ig6 = ref();
const ig7 = ref();
const contactSection = ref();

const elements = [ig1, ig2, ig3, ig4, ig5, ig6, ig7];
gsap.registerPlugin(ScrollTrigger);
onMounted(() => {
  elements.forEach((element, index) => {
    gsap.to(element.value, {
      opacity: 1,
      x: "0%",
      duration: 2.5,
      delay: index / 12,
      ease: "elastic.out(2, 0.9)",
      scrollTrigger: {
        trigger: contactSection.value,
        start: "0% 90%",
      },
    });
  });
});
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 3rem;
  left: 0;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
}
.left_top {
  max-width: 75%;
  margin-top: 6rem;
  position: relative;
}
.left_top h1 {
  font-size: 50px;
  line-height: 50px;
}
.left_top h2 {
  font-size: 1rem;
  color: #f2f2f2;
  font-weight: 300;
}
.btn {
  background-color: #eb5e28;
  color: white !important;
  border-radius: 20px;
  width: fit-content;
}
.btn h2 {
  padding: 10px 25px;
}

label {
  color: white;
}
.contact_buttons {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.loading__container {
  margin: 0;
  align-self: center;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.form {
  padding: 3rem;
  align-self: left;
  z-index: 1;
  width: 50%;
  max-width: 700px;
  z-index: 20;
  position: relative;
}

.contact_buttons,
.input__group,
.input__group__CheckBox {
  opacity: 0;
  transform: translateX(100px);
}
.input__group {
  position: relative;
}
.input__group p {
  font-size: 0.7rem;
  transition: 0.3s all;
  color: red;
}
.invalid {
  border-bottom: 1px red solid;
}
.invalid__text {
  color: red;
}
.input__group,
h1 {
  margin: 1rem 0rem;
}
input,
textarea {
  border: none;
  border-bottom: 1px #eb5e28 solid;
  background: transparent;
  border-bottom: 1px solid #eb5e28;
}
input:focus,
textarea:focus {
  outline: none;
  border-bottom: 1px #eb5f288c solid;
  transform: translateX(1.5%);
}
input,
textarea,
button {
  height: 2.2rem;
  width: 100%;
  color: white;
}
textarea {
  width: 100%;
  max-width: 900px;
  max-height: 300px;
  min-height: 4rem;
}
button {
  background-color: #eb5e28;
  border: none;
  color: #e5e5e5;
  font-size: 1rem;
  cursor: pointer;
  height: 3rem;
  transition: 0.3s all;
  border-radius: 6px;
}
button:hover {
  background-color: #eb5f288c;
}
label {
  font-size: 1rem;
  font-weight: 500;
}
.input__group__CheckBox {
  display: flex;
  align-items: flex-start;
  justify-content: left;
}
.input__group__CheckBox a {
  color: #eb5e28;
  text-decoration: underline;
}
.input__group__CheckBox input {
  width: unset;
  height: unset;
  box-shadow: unset;
  cursor: pointer;
}
.input__group__CheckBox input {
  background-color: #eb5e28;
}
.input__group__CheckBox h1 {
  margin: 0 1rem;
  color: rgb(179, 179, 179);
  font-size: 0.6rem;
  line-height: 1.2rem;
  max-width: 100% !important;
}
@media screen and (max-width: 700px) {
  .left_top {
    max-width: 100%;
  }
  .left_top h1 {
    font-size: 30px;
    line-height: 30px;
  }
  .contact_buttons {
    margin: 1rem 0;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .form {
    padding: 0rem;
    align-self: left;
    z-index: 1;
    width: 100%;
  }
}
.contact_buttons img {
  width: 70px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  input,
  textarea {
    font-size: 0.7rem;
  }
  button {
    font-size: 1rem;
  }
  label {
    font-size: 0.8rem;
  }
  .input__group,
  h1 {
    margin: 0.5rem 0rem;
  }
  .input__group__CheckBox h1 {
    margin: 0 1rem;
    color: rgb(179, 179, 179);
    font-size: 0.5rem;
    line-height: 1rem;
    max-width: 100% !important;
  }
}
@media screen and (min-width: 2000px) {
  .form {
    padding: 3rem;
    align-self: left;
    z-index: 1;
    width: 70%;
    max-width: 900px;
    z-index: 20;
    position: relative;
  }
  .left_top {
    max-width: 65%;
    z-index: 20;
  }
  input,
  textarea,
  button {
    height: 3.2rem;
    font-size: 1.2rem;
  }
  textarea {
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    min-height: 8rem;
  }
  button {
    background-color: #eb5e28;
    border: none;
    color: #e5e5e5;
    font-size: 1.2rem;
    cursor: pointer;
    height: 3rem;
    transition: 0.3s all;
    border-radius: 6px;
  }
  button:hover {
    background-color: #fca21183;
  }
  label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .input__group p {
    font-size: 1.3rem;
  }
  .contact_buttons img {
    width: 120px;
    cursor: pointer;
  }
}
</style>

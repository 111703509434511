<template>
  <section ref="blog" id="blog" class="container">
    <div ref="article1" class="blogCard">
      <div
        :style="{ 'background-image': 'url(blog/webpage.jpg)' }"
        class="img__box"
      ></div>
      <div class="content__box">
        <header><h2>Strona internetowa</h2></header>
        <span>Dlaczego Twój biznes potrzebuje witryny?</span><br />
        <!-- <span>{{ new Date().toLocaleDateString("pl-PL") }}</span> -->
        <div class="line"></div>
        <h4>
          W dobie rosnącej cyfryzacji i coraz większej liczby konsumentów
          spędzających czas online, posiadanie swojej witryny internetowej staje
          się nie tylko opcją, ale wręcz koniecznością. Oto kilka powodów, dla
          których warto zainwestować w swoją stronę internetową:
        </h4>
        <br />
        <ul>
          <li>
            <b> Obecność w Sieci:</b> Internet stał się głównym miejscem, gdzie
            ludzie szukają informacji o produktach i usługach. Posiadanie swojej
            strony internetowej pozwala potencjalnym klientom łatwo znaleźć
            Twoją firmę i zapoznać się z jej ofertą.
          </li>
          <li>
            <b>Wiarygodność:</b> Strona internetowa działa jak wizytówka Twojego
            biznesu w świecie online. Profesjonalnie wykonana witryna z
            odpowiednimi informacjami o firmie, produktach i referencjami buduje
            zaufanie u klientów.
          </li>
          <li>
            <b>Skuteczna Reklama:</b> Strona internetowa to doskonałe narzędzie
            reklamowe. Możesz na niej promować swoje produkty, udostępniać
            informacje o nowościach i ofertach specjalnych, co może przyciągnąć
            nowych klientów.
          </li>
          <li>
            <b>Dostępność 24/7:</b> W przeciwieństwie do tradycyjnych sklepów,
            witryna internetowa jest dostępna dla klientów przez całą dobę, 7
            dni w tygodniu. To oznacza, że potencjalni klienci mogą zapoznać się
            z Twoją ofertą o dowolnej porze dnia i nocy.
          </li>
          <li>
            <b>Globalny Zasięg:</b> Internet pozwala na dotarcie do klientów nie
            tylko lokalnych, ale także globalnych. Dzięki stronie internetowej
            Twoja firma może przyciągać klientów z różnych części świata.
          </li>
          <li>
            <b>Analiza Danych:</b> Posiadając własną stronę internetową, masz
            również możliwość monitorowania ruchu na stronie, analizowania
            zachowań klientów i dostosowywania strategii marketingowej do ich
            potrzeb.
          </li>
        </ul>
        <br />
        <h4>
          Warto pamiętać, że posiadanie stronie internetowej to nie tylko
          luksus, ale wręcz inwestycja w rozwój i sukces Twojego biznesu w erze
          cyfrowej. Dlatego zachęcam wszystkich właścicieli firm do rozważenia
          stworzenia własnej witryny internetowej, która będzie potężnym
          narzędziem w zdobywaniu nowych klientów i rozwijaniu swojej
          działalności.
        </h4>
      </div>
      <div class="tags">
        <!-- <div class="tag red">AI</div> -->
        <div class="tag blue">Innovative</div>
        <div class="tag green">IT</div>
      </div>
    </div>
    <div ref="article2" class="blogCard">
      <div
        :style="{ 'background-image': 'url(blog/cloud.jpg)' }"
        class="img__box"
      ></div>
      <div class="content__box">
        <header><h2>Chmura</h2></header>
        <span>Korzyści migracji do chmury</span><br />
        <!-- <span>{{ new Date().toLocaleDateString("pl-PL") }}</span> -->
        <div class="line"></div>
        <h4>
          Coraz więcej przedsiębiorstw decyduje się przenieść swoje dane i
          aplikacje do chmury, co niesie za sobą wiele korzyści. Oto dlaczego
          warto rozważyć ten krok:
        </h4>
        <ul>
          <li>
            <b>Skalowalność:</b> Chmura oferuje nieograniczoną skalowalność, co
            oznacza, że możesz łatwo dostosować zasoby do zmieniających się
            potrzeb biznesowych. Bez konieczności inwestowania w nowy sprzęt,
            możesz elastycznie dostosowywać swoje środowisko do rosnącego
            obciążenia lub zmieniających się wymagań.
          </li>
          <li>
            <b>Oszczędność Kosztów:</b> Migracja do chmury może przynieść
            znaczne oszczędności kosztów. Eliminuje konieczność zakupu,
            instalacji i konserwacji własnego sprzętu oraz oprogramowania.
            Ponadto, model płatności „pay-as-you-go” pozwala płacić tylko za
            zużyte zasoby, co może być korzystne dla małych i średnich
            przedsiębiorstw.
          </li>
          <li>
            <b>Elastyczność:</b> Dzięki chmurze możesz pracować z dowolnego
            miejsca i urządzenia, o każdej porze dnia i nocy. Elastyczność ta
            umożliwia pracę zdalną, co staje się coraz bardziej istotne w
            obecnym środowisku biznesowym.
          </li>
          <li>
            <b>Zwiększona Wydajność:</b> Chmura oferuje zaawansowane narzędzia
            do zarządzania danymi i automatyzacji procesów biznesowych. Dzięki
            temu możesz zwiększyć wydajność pracy swoich pracowników i
            zoptymalizować działanie swojej firmy.
          </li>
          <li>
            <b>Bezpieczeństwo Danych:</b> Usługi chmurowe często oferują
            zaawansowane mechanizmy zabezpieczeń, w tym szyfrowanie danych,
            zabezpieczenia wielopoziomowe i monitorowanie zdarzeń. To sprawia,
            że Twoje dane są chronione nawet w przypadku awarii sprzętu lub
            ataku hakerskiego.
          </li>
          <li>
            <b>Łatwa Integracja:</b> Chmura umożliwia łatwą integrację z innymi
            systemami i aplikacjami, co ułatwia wymianę danych między różnymi
            platformami i usprawnia procesy biznesowe.
          </li>
        </ul>
        <h4>
          Migracja do chmury może być kluczowym krokiem w modernizacji i
          optymalizacji Twojej infrastruktury IT. Daje ona firmom możliwość
          dostępu do zaawansowanych technologii i narzędzi, które mogą
          przyczynić się do wzrostu efektywności i konkurencyjności na rynku.
          <br /><br />
          Jeśli jeszcze nie rozważałeś migracji do chmury, teraz jest właściwy
          moment, aby to zrobić i czerpać korzyści z nowej ery w zarządzaniu
          danymi.
        </h4>
      </div>
      <div class="tags">
        <div class="tag red">Infrastructure</div>
        <div class="tag green">IT</div>
      </div>
    </div>
    <div ref="article3" class="blogCard">
      <div
        :style="{ 'background-image': 'url(blog/lossData.jpg)' }"
        class="img__box"
      ></div>
      <div class="content__box">
        <header><h2>Backup</h2></header>
        <span>Jak Zabezpieczyć Swoje Dane?</span><br />
        <span>{{ new Date().toLocaleDateString("pl-PL") }}</span>
        <div class="line"></div>
        <h4>
          Zabezpieczenie danych poprzez regularne tworzenie kopii zapasowych,
          czyli tzw. backupu. Oto kilka kluczowych wskazówek, jak skutecznie
          zabezpieczyć swoje dane:
        </h4>
        <ul>
          <li>
            <b>Wybierz Prawidłową Strategię Backupu:</b> Istnieją różne
            strategie tworzenia kopii zapasowych, takie jak pełne, różnicowe czy
            inkrementalne. Wybierz tę, która najlepiej odpowiada Twoim potrzebom
            i środowisku pracy.
          </li>
          <li>
            <b>Regularność Jest Kluczem:</b> Regularność tworzenia kopii
            zapasowych jest kluczowa. Określ harmonogram backupu i trzymaj się
            go. Im częściej tworzysz kopie zapasowe, tym mniejsze ryzyko utraty
            danych.
          </li>
          <li>
            <b>Zróżnicowane Lokalizacje Backupu:</b> Nie trzymaj wszystkich
            kopii zapasowych w jednym miejscu. Wybierz kilka różnych
            lokalizacji, w tym także zewnętrzne dyski twarde, serwery chmurowe
            czy nawet fizyczne nośniki, aby zminimalizować ryzyko utraty danych
            w przypadku awarii jednego z systemów.
          </li>
          <li>
            <b>Automatyzacja Procesu Backupu:</b> Skorzystaj z narzędzi i
            programów, które umożliwiają automatyzację procesu tworzenia kopii
            zapasowych. Dzięki temu unikniesz ludzkich błędów i zapewnisz, że
            backup zostanie wykonany regularnie zgodnie z harmonogramem.
          </li>
          <li>
            <b>Zabezpiecz Dane Szyfrowaniem:</b> Zanim zdecydujesz się na
            przechowywanie kopii zapasowych w chmurze czy na zewnętrznym
            nośniku, upewnij się, że Twoje dane są odpowiednio zabezpieczone
            szyfrowaniem. W ten sposób zapobiegniesz dostępowi osób
            niepowołanych do Twoich informacji.
          </li>
          <li>
            <b>Regularne Testowanie Kopii Zapasowych:</b> Nie zapominaj o
            regularnym testowaniu swoich kopii zapasowych, aby upewnić się, że
            są one kompleksowe i łatwo przywracalne w przypadku potrzeby.
            Testowanie pozwoli również na wykrycie ewentualnych problemów z
            backupem i ich naprawę przed utratą danych.
          </li>
        </ul>
        <br /><br />
        <h4>
          Pamiętaj, że zabezpieczenie danych poprzez regularne tworzenie kopii
          zapasowych jest kluczowym elementem dbania o bezpieczeństwo informacji
          w erze cyfrowej. Dlatego też nie bagatelizuj tego procesu i zadbaj o
          regularność oraz kompleksowość swoich backupów.
        </h4>
      </div>
      <div class="tags">
        <div class="tag yellow">Security</div>
        <div class="tag green">IT</div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ref, onMounted } from "vue";

const blog = ref();
const article1 = ref();
const article2 = ref();
const article3 = ref();

const articles = [article1, article2, article3];

gsap.registerPlugin(ScrollTrigger);
onMounted(() => {
  articles.forEach((article) => {
    gsap.to(article.value, {
      opacity: 1,
      x: "0%",
      duration: 2.5,
      ease: "elastic.out(2, 0.9)",
      scrollTrigger: {
        trigger: article.value,
        start: "0% 70%",
      },
    });
  });
});
</script>

<style scoped>
section {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;
}
.blogCard {
  opacity: 0;
  transform: translateX(50px);
  display: flex;
  gap: 1rem;
  background-color: #403d3986;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.14);
  position: relative;
}
.tags {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.tag {
  font-size: 0.9rem;
  color: #fffcf2;
  padding: 0.2rem 1rem;
  border-radius: 20px;
}
.red {
  background-color: #e0234e;
}
.green {
  background-color: #00dc82;
}
.blue {
  background-color: #326ce5;
}
.yellow {
  background-color: #f7df1e;
  color: black;
}

.img__box {
  border-radius: 7px;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 400px;
  min-height: 300px;
}
h2,
h4,
span {
  margin: 0;
  color: #fffcf2;
}
.content__box {
  padding-bottom: 2rem;
}
h4,
ul {
  font-weight: lighter;
}
span {
  opacity: 0.3;
}
.line {
  width: 30px;
  border-radius: 15px;
  height: 4px;
  margin: 0.5rem 0;
  background-color: #eb5e28;
}
ul {
  padding: 0;
  color: #fffcf2;
  list-style: none;
}
@media screen and (max-width: 1000px) {
  .blogCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #403d3986;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.14);
    position: relative;
    max-width: 90%;
  }
  .img__box {
    min-width: unset !important;
  }
  section {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;
  }
}
</style>

<template>
  <the-header></the-header>
  <BackGround></BackGround>
  <router-view></router-view>
  <transition name="cookies">
    <TheCookieBaner></TheCookieBaner>
  </transition>
</template>

<script setup>
import TheHeader from "./components/UI/TheHeader.vue";
import BackGround from "./components/SVGs/BackGround.vue";
import TheCookieBaner from "./components/UI/TheCookieBaner.vue";
import { storeToRefs } from "pinia";
import { useUtilitiesStore } from "@/store/utils";
const store = useUtilitiesStore();
const { bgColor2 } = storeToRefs(store);
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: #252422;
  transition: 0.3s background-color;
}
#app {
  overflow-x: hidden;
}
.heading_font {
  font-family: "Bebas Neue", sans-serif;
  color: #f2f2f2;
}
a {
  text-decoration: none;
}
* {
  font-family: "Red Hat Display", sans-serif;
  scrollbar-width: normal;
  scrollbar-color: rgb(0, 0, 0);
}
*::-webkit-scrollbar {
  width: 0.5vw;
}
*::-webkit-scrollbar-thumb {
  background-color: v-bind(bgColor2);
  border-radius: 20px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
  border-left: rgba(180, 22, 95, 0.4) 1px solid;
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateX(0%);
}
.route-leave-active {
  transition: all 0.2s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.route-enter-from {
  opacity: 0;
  transform: translateX(-100%);
}
.cookies-enter-active {
  transition: all 0.3s ease-out;
}
.cookies-enter-to,
.cookies-leave-from {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.cookies-leave-active {
  transition: all 0.3s ease-out;
}
.cookies-leave-to,
.cookies-enter-from {
  opacity: 0;
  transform: translateY(-200px) scale(0.7);
}
.orange {
  color: #eb5e28;
}
</style>

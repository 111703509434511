<template>
  <main>
    <section-badge></section-badge>
    <section-badge-service></section-badge-service>
    <section-badge-blog></section-badge-blog>
    <div id="contact">
      <section-badge-contact></section-badge-contact>
    </div>
  </main>
</template>
<script setup></script>

<template>
  <svg
    width="153"
    height="153"
    viewBox="0 0 153 153"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Loading">
      <g id="LoadingCircle">
        <path
          id="Circle"
          d="M137 76C137 109.689 109.689 137 76 137C42.3106 137 15 109.689 15 76C15 42.3106 42.3106 15 76 15C109.689 15 137 42.3106 137 76ZM27.8176 76C27.8176 102.61 49.3896 124.182 76 124.182C102.61 124.182 124.182 102.61 124.182 76C124.182 49.3896 102.61 27.8176 76 27.8176C49.3896 27.8176 27.8176 49.3896 27.8176 76Z"
          :style="{ fill: color2 }"
          fill-opacity="0.35"
        />
        <path
          id="loading"
          d="M137 76C137 67.9894 135.422 60.0572 132.357 52.6563C129.291 45.2554 124.798 38.5309 119.134 32.8665C113.469 27.2021 106.745 22.7089 99.3437 19.6433C91.9428 16.5778 84.0106 15 76 15L76 27.8176C82.3274 27.8176 88.5929 29.0638 94.4386 31.4852C100.284 33.9066 105.596 37.4557 110.07 41.9299C114.544 46.404 118.093 51.7156 120.515 57.5614C122.936 63.4071 124.182 69.6726 124.182 76H137Z"
          :style="{ fill: color }"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["color", "color2"],
};
</script>

<style scoped>
svg {
  margin: 0;
  width: 70px;
  height: 70px;
  animation: rotating 1s infinite ease-in-out;
}
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

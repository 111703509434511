<template>
  <div class="burger" :class="{ toggle: props.isOpen }" @click="toggle">
    <span class="line line1"></span>
    <span class="line line2"></span>
    <span class="line line3"></span>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUtilitiesStore } from "@/store/utils";
import { defineProps, defineEmits } from "vue";
const store = useUtilitiesStore();
const { bgColor2 } = storeToRefs(store);
const emit = defineEmits(["toggle-nav"]);
const props = defineProps({
  isOpen: Boolean,
});
const toggle = () => {
  emit("toggle-nav");
};
</script>

<style scoped>
.burger {
  cursor: pointer;
  width: 2rem;
  height: 1.2rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  transition: 0.3s all;
}
.line {
  width: 100%;
  height: 3px;
  background-color: v-bind(bgColor2);
  border-radius: 30px;
  transition: 0.3s all;
}
.line1,
.line3 {
  width: 50%;
}
.line3 {
  align-self: flex-end;
}
.burger.toggle span:nth-child(1) {
  width: 100%;
  transform: translateY(8px) rotate(45deg);
  transition-delay: 0.125s;
}
.burger.toggle span:nth-child(2) {
  transform: rotate(-45deg);
}
.burger.toggle span:nth-child(3) {
  width: 100%;
  transform: scaleX(0);
  transform-origin: right;
  transition-delay: 0.125s;
}
@media screen and (max-width: 1000px) {
  .burger {
    display: flex;
  }
}
</style>

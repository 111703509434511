<template>
  <section>
    <div class="left_top content box">
      <h1 ref="h1" class="heading_font">Innowacyjne usługi IT</h1>
      <h2 ref="h2">
        Nowoczesne aplikacje webowe, wsparcie techniczne na żądanie i
        profesjonalne usługi DevOps
      </h2>
      <TheButton text="Sprawdź!" />
      <div class="btn__container">
        <div ref="btn1" class="btn btn_more">
          <router-link
            :to="{ path: '/', hash: '#contact' }"
            active-class="link-active"
          >
            <h2>Kontakt</h2>
          </router-link>
        </div>
        <div ref="btn2" class="btn">
          <router-link
            :to="{ path: '/', hash: '#services' }"
            active-class="link-active"
          >
            <h2>Sprawdź!</h2>
          </router-link>
        </div>
      </div>
    </div>
    <div class="right_bottom asset box">
      <WebApp />
    </div>
  </section>
</template>

<script setup>
import WebApp from "../SVGs/WebApp.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useUtilitiesStore } from "@/store/utils";
const store = useUtilitiesStore();
const { bgColor2 } = storeToRefs(store);

const h1 = ref();
const h2 = ref();
const btn1 = ref();
const btn2 = ref();
const elements = [h1, h2, btn1, btn2];

gsap.registerPlugin(ScrollTrigger);
onMounted(() => {
  bgColor2.value = "#EB5E28";
  elements.forEach((element, index) => {
    gsap.fromTo(
      element.value,
      { opacity: 0, x: "10%" },
      { opacity: 1, x: "0%", duration: 0.3, delay: index / 5 }
    );
  });
});
</script>

<style scoped>
section {
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8rem 3rem 0rem 3rem;
  flex-wrap: wrap;
  overflow: hidden;
  transition: 0.3s all;
}
.left_top {
  max-width: 40%;
}
.left_top h1 {
  font-size: 50px;
  line-height: 50px;
  margin: 0;
}
.left_top h2 {
  font-size: 1rem;
  color: #f2f2f2;
  font-weight: 300;
}
.btn__container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0;
}
.btn {
  background-color: #eb5e28;
  color: white !important;
  border-radius: 30px;
  width: fit-content;
}
.btn_more {
  background-color: transparent !important;
  color: #fffcf2;
  border: 2px #eb5e28 solid;
  border-style: inset;
}
.btn:hover {
  opacity: 0.8;
}
.btn h2 {
  padding: 8px 30px;
  margin: 0;
  font-size: 1rem;
}

.right_bottom {
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
}

@media screen and (max-width: 1236px) {
  .left_top {
    max-width: 45%;
    margin-top: 2rem;
  }
  .left_top h1 {
    font-size: 40px;
    line-height: 40px;
  }
  .right_bottom {
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 700px) {
  .left_top {
    max-width: 85%;
  }
  .left_top h1 {
    font-size: 30px;
    line-height: 30px;
  }
  .right_bottom {
    width: 500px;
  }
}

@media screen and (min-width: 2000px) {
  section {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 4rem;
    left: 0;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .left_top {
    max-width: 30%;
  }
  .left_top h1 {
    font-size: 80px;
    line-height: 80px;
  }
  .left_top h2 {
    font-size: 1.4rem;
    color: #f2f2f2;
    font-weight: 300;
  }

  .right_bottom {
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
